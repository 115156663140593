<div class="business-owner">
  <div class="business-owner-content">
    <h1>Business Owner Registration</h1>
    <p>Do you have a code?</p>
    <div class="button-group mb-3">
      <button id="no-code-button" class="btn btn-primary" (click)="onNoClick()" style="background-color: #FF9800; color: black; border: none;">No</button>
      <button id="yes-code-button" class="btn btn-primary" (click)="onYesClick()" style="background-color: #FF9800; color: black; border: none;">Yes</button>
    </div>

    <div *ngIf="showCodeEntry" class="code-entry">
      <div class="input-group mb-3 input-container">
        <input type="text" class="form-control text-center" placeholder="Enter Code" [(ngModel)]="code">
        <button id="clear-code-button" class="btn clear-btn" (click)="clearCode()">Clear</button>
      </div>
      <div class="spinner-container" *ngIf="isCodeLoading">
        <div class="spinner-border text-light" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>
      <button id="view-code-button" class="btn btn-primary" (click)="viewCode()" style="background-color: #FF9800; color: black; border: none;" [disabled]="isCodeLoading">
        <span *ngIf="!isCodeLoading">View</span>
        <span *ngIf="isCodeLoading">Loading...</span>
      </button>
      <div *ngIf="codeError" class="alert alert-danger mt-2" role="alert">
        {{ codeError }}
      </div>
    </div>

    <div *ngIf="showCompanyNameEntry" class="company-name-entry">
      <!-- Personal Information Fields -->
      <div class="mb-3">
        <label for="firstName" class="form-label">First Name</label>
        <div class="input-group input-container">
          <input type="text" id="firstName" class="form-control text-center" placeholder="Please enter first name" [(ngModel)]="firstName">
          <button id="clear-firstName-button" class="btn clear-btn" (click)="clearFirstName()">Clear</button>
        </div>
      </div>
      <div class="mb-3">
        <label for="lastName" class="form-label">Last Name</label>
        <div class="input-group input-container">
          <input type="text" id="lastName" class="form-control text-center" placeholder="Please enter last name" [(ngModel)]="lastName">
          <button id="clear-lastName-button" class="btn clear-btn" (click)="clearLastName()">Clear</button>
        </div>
      </div>
      <div class="mb-3">
        <label for="email" class="form-label">Email</label>
        <div class="input-group input-container">
          <input type="email" id="email" class="form-control text-center" placeholder="Please enter email" [(ngModel)]="email">
          <button id="clear-email-button" class="btn clear-btn" (click)="clearEmail()">Clear</button>
        </div>
      </div>
      <!-- Business Role Selection -->
      <div class="mb-3">
        <label for="businessRole" class="form-label">Business Role</label>
        <div class="input-group input-container">
          <select id="businessRole" class="form-control text-center" [(ngModel)]="businessRole">
            <option value="" disabled selected hidden>Please select your role</option>
            <option *ngFor="let role of businessRoles" [value]="role">{{ role }}</option>
          </select>
          <button id="clear-businessRole-button" class="btn clear-btn" (click)="clearBusinessRole()">Clear</button>
        </div>
      </div>
      <!-- Authorization Checkbox -->
      <div class="mb-3 form-check">
        <input type="checkbox" class="form-check-input" id="authorizedToDecide" [(ngModel)]="authorizedToDecide">
        <label class="form-check-label" for="authorizedToDecide">
          I am authorized to make decisions on behalf of this company.
        </label>
      </div>
      <div class="mb-3">
        <label for="companyName" class="form-label">Company Name</label>
        <div class="input-group input-container">
          <input type="text" id="companyName" class="form-control text-center" placeholder="Please enter company name" [(ngModel)]="companyName">
          <button id="clear-companyName-button" class="btn clear-btn" (click)="clearCompanyName()">Clear</button>
        </div>
      </div>
      <div class="mb-3">
        <label for="city" class="form-label">City</label>
        <div class="input-group input-container">
          <input type="text" id="city" class="form-control text-center" placeholder="Please enter the city of the business" [(ngModel)]="city">
          <button id="clear-city-button" class="btn clear-btn" (click)="clearCity()">Clear</button>
        </div>
      </div>
      <!-- Business Type Selection -->
      <div class="mb-3">
        <label for="businessType" class="form-label">Business Type</label>
        <div class="input-group input-container">
          <select id="businessType" class="form-control text-center" [(ngModel)]="businessType" (change)="onBusinessTypeChange($event)">
            <option value="" disabled selected hidden>Please select the closest match</option>
            <option *ngFor="let type of businessTypes" [value]="type">{{ type }}</option>
          </select>
          <button id="clear-businessType-button" class="btn clear-btn" (click)="clearBusinessType()">Clear</button>
        </div>
      </div>
      <!-- Conditional Form Elements Based on Business Type -->
      <div *ngIf="businessType === 'Other'" class="mb-3">
        <label for="businessDescription" class="form-label">Business Description</label>
        <div class="input-group input-container">
          <input type="text" id="businessDescription" class="form-control text-center" placeholder="Please describe your business" [(ngModel)]="businessDescription">
          <button id="clear-businessDescription-button" class="btn clear-btn" (click)="clearBusinessDescription()">Clear</button>
        </div>
      </div>
      <div *ngIf="businessType === 'Experience'" class="mb-3">
        <label for="businessDescription" class="form-label">Overview</label>
        <div class="input-group input-container">
          <input type="text" id="businessDescription" class="form-control text-center" placeholder="Please enter a small overview" [(ngModel)]="businessDescription">
          <button id="clear-businessOverview-button" class="btn clear-btn" (click)="clearBusinessDescription()">Clear</button>
        </div>
      </div>
      <!-- Zip Code/Post Code Field -->
      <div *ngIf="['Accommodation', 'Attraction', 'Food'].includes(businessType)" class="mb-3">
        <label for="zipCode" class="form-label">Zip Code/Post Code</label>
        <div class="input-group input-container">
          <input type="text" id="zipCode" class="form-control text-center" placeholder="Please enter your Zip/Post Code" [(ngModel)]="zipCode">
          <button id="clear-zipCode-button" class="btn clear-btn" (click)="clearZipCode()">Clear</button>
        </div>
      </div>
      <!-- Data Processing Agreement -->
      <div class="mb-3 form-check">
        <input type="checkbox" class="form-check-input" id="agreeToTerms" [(ngModel)]="agreeToTerms">
        <label class="form-check-label" for="agreeToTerms">
          I agree to the processing of my data to receive information about Expedisplore.
        </label>
      </div>
      <!-- Error message display -->
      <div *ngIf="duplicateError" class="alert alert-danger" role="alert">
        {{ duplicateError }}
      </div>
      <!-- Cancel and Submit Buttons -->
      <div class="button-group mb-3">
        <button id="cancel-button" class="btn btn-secondary" (click)="cancel()" style="margin-right: 10px;">Cancel</button>
        <button id="submit-button" class="btn btn-primary" [disabled]="!isFormValid()" (click)="submitForm()" style="background-color: #FF9800; color: black; border: none;">Submit</button>
      </div>
    </div>
  </div>
</div>
