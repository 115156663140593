import { Component, ElementRef, Renderer2, ViewChild, OnInit, AfterViewInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Modal } from 'bootstrap';
import { Router } from '@angular/router';
import { ApiService } from '../api.service';
import { GoogleSigninService } from '../google-signin.service';
import keys from '../../../keys.json';

declare const google: any;

@Component({
  selector: 'app-hero',
  templateUrl: './hero.component.html',
  styleUrls: ['./hero.component.scss']
})
export class HeroComponent implements OnInit, AfterViewInit {
  userForm: FormGroup;
  @ViewChild('emailInput', { static: false }) emailInput!: ElementRef;
  isSuccess: boolean = false;
  modalTitle: string = "User Sign Up";
  isSubmitting: boolean = false;

  constructor(
    private fb: FormBuilder,
    private apiService: ApiService,
    private renderer: Renderer2,
    private router: Router,
    private googleSigninService: GoogleSigninService
  ) {
    this.userForm = this.fb.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      agree: [false, Validators.requiredTrue]
    });
  }

  ngOnInit(): void {
    (window as any).handleCredentialResponse = this.handleCredentialResponse.bind(this);

    this.googleSigninService.onGoogleApiLoad(() => {
      google.accounts.id.initialize({
        client_id: keys.GoogleOauth,
        callback: this.handleCredentialResponse.bind(this)
      });
    });
  }

  ngAfterViewInit(): void {
    this.googleSigninService.onGoogleApiLoad(() => {
      google.accounts.id.renderButton(
        document.getElementById('customGoogleButton'),
        { theme: "outline", size: "large" }
      );
    });
  }

  startGoogleSignIn(): void {
    this.isSubmitting = true;
  }

  handleCredentialResponse(response: any): void {
    console.log('Encoded JWT ID token: ' + response.credential);
    const userObject = this.parseJwt(response.credential);
    console.log('User Object: ', userObject);
    const firstName = userObject.given_name;
    const lastName = userObject.family_name;
    const email = userObject.email;

    this.userForm.patchValue({
      firstName: firstName,
      lastName: lastName,
      email: email,
      agree: true  // Automatically agree to the terms when using Google Sign-In
    });

    // Automatically submit the form after filling it with Google data
    this.submitForm();
  }

  parseJwt(token: string) {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
  }

  openModal(modalId: string, userType: string): void {
    if (userType === 'User') {
      this.isSuccess = false;
      this.modalTitle = "User Sign Up";
      const modalElement = document.getElementById(modalId);
      if (modalElement) {
        const modal = new Modal(modalElement);
        modal.show();
      }
    } else if (userType === 'Business Owner') {
      this.navigateToBusinessOwner();
    }
  }

  navigateToBusinessOwner(): void {
    this.router.navigate(['/business-owner']);
  }

  onSubmit(): void {
    this.removeErrorMessages(); // Clear existing error messages
    if (this.userForm.valid) {
      this.submitForm();
    } else {
      this.userForm.markAllAsTouched();
    }
  }

  submitForm(): void {
    this.isSubmitting = true;
    const formData = {
      firstname: this.userForm.value.firstName,
      lastname: this.userForm.value.lastName,
      email: this.userForm.value.email,
      sendEmailFlag: this.userForm.value.agree
    };

    this.apiService.submitForm(formData).subscribe(
      response => {
        console.log('Form Submitted', response);
        this.showSuccessMessage();
      },
      error => {
        this.isSubmitting = false;
        if (error.status === 409) {
          this.highlightEmailField();
          this.showEmailExistsMessage();
        } else {
          console.error('Error submitting form', error);
        }
      }
    );
  }

  clearForm(): void {
    this.userForm.reset();
    this.renderer.removeClass(this.emailInput.nativeElement, 'is-invalid');
    this.hideEmailExistsMessage();
  }

  showSuccessMessage(): void {
    this.isSubmitting = false;
    this.isSuccess = true;
    this.modalTitle = "Registration Successful";
    this.clearForm();
    this.closeModal();
    this.router.navigate(['/registration-success']);
  }

  highlightEmailField(): void {
    this.renderer.addClass(this.emailInput.nativeElement, 'is-invalid');
  }

  showEmailExistsMessage(): void {
    const emailExistsMessage = this.renderer.createElement('div');
    this.renderer.setProperty(emailExistsMessage, 'innerHTML', 'This email address already exists');
    this.renderer.addClass(emailExistsMessage, 'text-danger');
    this.renderer.setAttribute(emailExistsMessage, 'id', 'email-exists-message');
    this.renderer.appendChild(this.emailInput.nativeElement.parentNode, emailExistsMessage);
  }

  hideEmailExistsMessage(): void {
    const emailExistsMessage = document.getElementById('email-exists-message');
    if (emailExistsMessage) {
      emailExistsMessage.remove();
    }
  }

  removeErrorMessages(): void {
    const errorMessages = document.querySelectorAll('#email-exists-message');
    errorMessages.forEach(message => {
      message.remove();
    });
  }

  closeModal(): void {
    const modalElement = document.getElementById('userModal');
    if (modalElement) {
      const modal = Modal.getInstance(modalElement);
      if (modal) {
        modal.hide();
      }
    }
    this.removeModalBackdrops();
  }

  handleCancel(): void {
    this.clearForm();
    this.closeModal();
  }

  removeModalBackdrops(): void {
    const backdrops = document.getElementsByClassName('modal-backdrop');
    while (backdrops.length > 0) {
      backdrops[0].parentNode?.removeChild(backdrops[0]);
    }
  }
}
