import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  //private apiUrl = 'https://api.expedisplore.co.uk/interest/add-user-interest';  // Replace with your actual backend API URL
  private apiUrl = 'https://api.expedisplore.co.uk/interest/add-user-interest';  // Replace with your actual backend API URL
  private checkCompanyUrl = 'https://api.expedisplore.co.uk/interest/check-for-company'; // URL for checking the company
  private rejectInfoUrl = 'https://api.expedisplore.co.uk/interest/reject-information'; // URL for rejecting information
  private acceptInfoUrl = 'https://api.expedisplore.co.uk/interest/accept-information'; // URL for accepting information
  private codeCheckUrl = 'https://api.expedisplore.co.uk/interest/check-company-code'; // URL for accepting information

  constructor(private http: HttpClient) {}

  submitForm(formData: any): Observable<any> {
    return this.http.post(this.apiUrl, formData);
  }

  checkForCompany(formData: any): Observable<any> {
    return this.http.post(this.checkCompanyUrl, formData);
  }

  rejectInformation(formData: any): Observable<any> {
    return this.http.post(this.rejectInfoUrl, formData);
  }

  acceptInformation(formData: any): Observable<any> {
    return this.http.post(this.acceptInfoUrl, formData);
  }

  checkCode(formData: any): Observable<any> {
    return this.http.post(this.codeCheckUrl, formData);
  }
}
