import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from '../api.service';

@Component({
  selector: 'app-check-business',
  templateUrl: './check-business.component.html',
  styleUrls: ['./check-business.component.scss']
})
export class CheckBusinessComponent implements OnInit {
  company: any;
  originalRequest: any;

  constructor(public route: ActivatedRoute, public router: Router, private apiService: ApiService) {
    const navigation = this.router.getCurrentNavigation();
    const state = navigation?.extras?.state || {};
    this.company = state['company'] || {};
    this.originalRequest = state['originalRequest'] || {};
  }

  ngOnInit(): void {}

  formatOpeningHours(hours: any): string {
    return `${hours.open.time} - ${hours.close.time}`;
  }

  hasSocialMedia(): boolean {
    return this.company.social_media &&
      (this.company.social_media.facebook ||
      this.company.social_media.youtube ||
      this.company.social_media.instagram ||
      this.company.social_media.tiktok ||
      this.company.social_media.snapchat ||
      this.company.social_media.x ||
      this.company.social_media.pinterest ||
      this.company.social_media.linkedin ||
      this.company.social_media.tumblr ||
      this.company.social_media.tripadvisor);
  }

  rejectInformation(): void {
    const requestBody = {
      firstname: this.originalRequest.firstname,
      lastname: this.originalRequest.lastname,
      email: this.originalRequest.email,
      businessrole: this.originalRequest.businessrole,
      authorized: this.originalRequest.authorized,
      companyname: this.originalRequest.companyname,
      website: this.originalRequest.website,
      city: this.originalRequest.city,
      businesstype: this.originalRequest.businesstype,
      postcode: this.originalRequest.postcode,
      overview: this.originalRequest.overview,
      description: this.originalRequest.description,
      processing: this.originalRequest.processing
    };

    console.log('Reject Information Request Body:', requestBody); // Log the request body to the console

    this.apiService.rejectInformation(requestBody).subscribe(
      response => {
        console.log('Rejected Information:', response);
        if (response.message === 'Information received') {
          this.router.navigate(['/registration-success']);
        } else {
          this.router.navigate(['/error']);
        }
      },
      error => {
        console.error('Error rejecting information', error);
        this.router.navigate(['/error']);
      }
    );
  }

  acceptInformation(): void {
    const requestBody = {
      companyid: this.company._id,
      firstname: this.originalRequest.firstname,
      lastname: this.originalRequest.lastname,
      email: this.originalRequest.email,
      businessrole: this.originalRequest.businessrole,
      authorized: this.originalRequest.authorized,
      companyname: this.originalRequest.companyname,
      website: this.originalRequest.website,
      city: this.originalRequest.city,
      businesstype: this.originalRequest.businesstype,
      postcode: this.originalRequest.postcode,
      processing: this.originalRequest.processing
    };

    console.log('Accept Information Request Body:', requestBody); // Log the request body to the console

    this.apiService.acceptInformation(requestBody).subscribe(
      response => {
        console.log('Accepted Information:', response);
        if (response.message === 'Information accepted and linked to company') {
          this.router.navigate(['/registration-success']);
        } else {
          this.router.navigate(['/error']);
        }
      },
      error => {
        console.error('Error accepting information', error);
        this.router.navigate(['/error']);
      }
    );
  }
}
