<div class="check-business">
  <div class="check-business-content">
    <h1>Is this the company?</h1>
    <p class="update-info">This information might need updating</p>
    <h2 class="section-header">General</h2>
    <p><strong>Name:</strong> {{ company.name }}</p>
    <p><strong>Website: </strong> <a href="{{ company.website }}" target="_blank" class="custom-link">{{ company.website }}</a></p>
    <p><strong>Phone Number:</strong> {{ company.international_phone_number }}</p>
    <p><strong>Location:</strong> {{ company.city | titlecase }}</p>
    <p *ngIf="company.description"><strong>Description:</strong> {{ company.description }}</p>

    <h2 class="section-header">Opening Hours</h2>
    <div class="opening-hours" *ngFor="let day of company.opening_hours[0].hours">
      <p><strong>{{ day.open.day }}:</strong> {{ formatOpeningHours(day) }}</p>
    </div>

    <h2 *ngIf="company.social_media" class="section-header">Social Media</h2>
    <div class="social-media-icons" *ngIf="company.social_media">
      <a *ngIf="company.social_media.facebook" href="{{ company.social_media.facebook }}" target="_blank"><i class="fab fa-facebook"></i></a>
      <a *ngIf="company.social_media.youtube" href="{{ company.social_media.youtube }}" target="_blank"><i class="fab fa-youtube"></i></a>
      <a *ngIf="company.social_media.instagram" href="{{ company.social_media.instagram }}" target="_blank"><i class="fab fa-instagram"></i></a>
      <a *ngIf="company.social_media.tiktok" href="{{ company.social_media.tiktok }}" target="_blank"><i class="fab fa-tiktok"></i></a>
      <a *ngIf="company.social_media.snapchat" href="{{ company.social_media.snapchat }}" target="_blank"><i class="fab fa-snapchat"></i></a>
      <a *ngIf="company.social_media.x" href="{{ company.social_media.x }}" target="_blank"><i class="fab fa-x-twitter"></i></a>
      <a *ngIf="company.social_media.pinterest" href="{{ company.social_media.pinterest }}" target="_blank"><i class="fab fa-pinterest"></i></a>
      <a *ngIf="company.social_media.linkedin" href="{{ company.social_media.linkedin }}" target="_blank"><i class="fab fa-linkedin"></i></a>
      <a *ngIf="company.social_media.tumblr" href="{{ company.social_media.tumblr }}" target="_blank"><i class="fab fa-tumblr"></i></a>
      <a *ngIf="company.social_media.tripadvisor" href="{{ company.social_media.tripadvisor }}" target="_blank"><i class="fab fa-tripadvisor"></i></a>
    </div>

    <div class="button-group">
      <button id="no-button" class="btn btn-secondary" (click)="rejectInformation()">No</button>
      <button id="yes-button" class="btn btn-primary" (click)="acceptInformation()" style="background-color: #FF9800; color: black; border: none;">Yes</button>
    </div>
  </div>
</div>
