import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from '../api.service';

@Component({
  selector: 'app-business-owner',
  templateUrl: './business-owner.component.html',
  styleUrls: ['./business-owner.component.scss']
})
export class BusinessOwnerComponent implements OnInit {
  showCodeEntry: boolean = false;
  showCompanyNameEntry: boolean = false;
  code: string = '';
  firstName: string = '';
  lastName: string = '';
  email: string = '';
  companyName: string = '';
  website: string = '';
  city: string = '';
  businessType: string = '';
  businessDescription: string = '';
  zipCode: string = '';
  businessRole: string = '';
  agreeToTerms: boolean = false;
  authorizedToDecide: boolean = false;
  duplicateError: string = '';  
  codeError: string = '';  // Add this line
  isCodeLoading: boolean = false;  // Add this line

  businessTypes: string[] = ['Accommodation', 'Attraction', 'Experience', 'Food', 'Other'].sort();
  businessRoles: string[] = ['Owner', 'Director', 'Manager', 'CEO', 'Partner', 'Employee'];

  constructor(private router: Router, private apiService: ApiService) {}

  ngOnInit(): void {
    this.businessType = '';
  }

  onNoClick(): void {
    this.showCodeEntry = false;
    this.showCompanyNameEntry = true;
  }

  onYesClick(): void {
    this.showCodeEntry = true;
    this.showCompanyNameEntry = false;
  }

  onBusinessTypeChange(event: Event): void {
    const selectElement = event.target as HTMLSelectElement;
    this.businessType = selectElement.value;
  }

  isFormValid(): boolean {
    if (this.businessType === 'Other') {
      return !!this.firstName && !!this.lastName && !!this.email && !!this.companyName && !!this.city && !!this.businessDescription && !!this.businessRole && !!this.agreeToTerms && !!this.authorizedToDecide;
    } else if (['Accommodation', 'Attraction', 'Food'].includes(this.businessType)) {
      return !!this.firstName && !!this.lastName && !!this.email && !!this.companyName && !!this.city && !!this.zipCode && !!this.businessRole && !!this.agreeToTerms && !!this.authorizedToDecide;
    } else if (this.businessType === 'Experience') {
      return !!this.firstName && !!this.lastName && !!this.email && !!this.companyName && !!this.city && !!this.businessDescription && !!this.businessRole && !!this.agreeToTerms && !!this.authorizedToDecide;
    }
    return false;
  }

  cancel(): void {
    this.router.navigate(['/']);
  }

  submitForm(): void {
    this.duplicateError = ''; // Clear previous error message
    if (this.isFormValid()) {
      const formData = {
        firstname: this.firstName,
        lastname: this.lastName,
        email: this.email,
        businessrole: this.businessRole,
        authorized: this.authorizedToDecide,
        companyname: this.companyName,
        website: this.website,
        city: this.city,
        businesstype: this.businessType,
        postcode: this.zipCode,
        overview: this.businessType === 'Experience' ? this.businessDescription : '',
        description: this.businessType === 'Other' ? this.businessDescription : '',
        processing: this.agreeToTerms
      };
      
      this.apiService.checkForCompany(formData).subscribe(response => {
        if (response.match) {
          this.router.navigate(['/check-business'], { state: { company: response.company, originalRequest: response.originalRequest } });
        } else if (response.message === 'Duplicate information found for this company/user') {
          this.duplicateError = 'Duplicate information found for this company/user';  // Set the error message
        } else {
          this.router.navigate(['/registration-success']);
        }
      });
    } else {
      console.error('Please fill in all required fields');
    }
  }

  clearCode(): void {
    this.code = '';
  }

  clearFirstName(): void {
    this.firstName = '';
  }

  clearLastName(): void {
    this.lastName = '';
  }

  clearEmail(): void {
    this.email = '';
  }

  clearCompanyName(): void {
    this.companyName = '';
  }

  clearWebsite(): void {
    this.website = '';
  }

  clearCity(): void {
    this.city = '';
  }

  clearBusinessType(): void {
    this.businessType = '';
  }

  clearBusinessDescription(): void {
    this.businessDescription = '';
  }

  clearZipCode(): void {
    this.zipCode = '';
  }

  clearBusinessRole(): void {
    this.businessRole = '';
  }

  viewCode(): void {
    this.codeError = '';  // Clear previous error message
    this.isCodeLoading = true;  // Set loading state

    if (this.code) {
      const formData = {
        companyid: this.code
      };

      this.apiService.checkCode(formData).subscribe(
        response => {
          this.isCodeLoading = false;  // Clear loading state
          if (response.value) {
            window.location.href = response.value;  // Redirect to the URL
          } else {
            this.codeError = 'Code not found';  // Set error message
          }
        },
        error => {
          this.isCodeLoading = false;  // Clear loading state
          this.codeError = 'Error occurred while checking the code';  // Set error message
        }
      );
    } else {
      this.isCodeLoading = false;  // Clear loading state
      this.codeError = 'Please enter a code';  // Set error message
    }
  }
}
