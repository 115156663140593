import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HeroComponent } from './hero/hero.component';
import { BusinessOwnerComponent } from './business-owner/business-owner.component';
import { RegistrationSuccessComponent } from './registration-success/registration-success.component';
import { CheckBusinessComponent } from './check-business/check-business.component';
import { ErrorPageComponent } from './error-page/error-page.component';

const routes: Routes = [
  { path: '', component: HeroComponent },
  { path: 'business-owner', component: BusinessOwnerComponent },
  { path: 'registration-success', component: RegistrationSuccessComponent },
  { path: 'check-business', component: CheckBusinessComponent },
  { path: 'error', component: ErrorPageComponent },
  { path: '**', redirectTo: '' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
