<div class="hero">
  <div class="hero-content">
    <h1>PLAN. EXPLORE. DISCOVER</h1>
    <p>WITH</p>
    <h1>EXPEDISPLORE</h1>
    <p class="register-interest">Register Interest</p>
    <div class="buttons">
      <button class="btn-left" (click)="openModal('userModal', 'Business Owner')">Business Owner</button>
      <button class="btn-right" (click)="openModal('userModal', 'User')">User</button>
    </div>
    <!-- Links for Privacy Policy and Terms of Service -->
    <div class="links" style="margin-top: 20px; color: #FFF;">
      <a href="https://expedisplore.com/terms.html" target="_blank" style="text-decoration: none; color: #FFF;">Terms of Service</a> 
      <span style="margin: 0 10px;">|</span>
      <a href="https://expedisplore.com/privacy-policy.html" target="_blank" style="text-decoration: none; color: #FFF;">Privacy Policy</a>
    </div>
  </div>
</div>

<!-- User Sign Up Modal -->
<div class="modal fade" id="userModal" tabindex="-1" aria-labelledby="userModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="userModalLabel">{{ modalTitle }}</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <hr>
        <div *ngIf="!isSuccess">
          <form [formGroup]="userForm" (ngSubmit)="onSubmit()">
            <div class="mb-3">
              <label for="firstName" class="form-label">First Name</label>
              <input type="text" class="form-control" id="firstName" formControlName="firstName" placeholder="Enter your first name">
              <div *ngIf="userForm.controls['firstName'].invalid && userForm.controls['firstName'].touched" class="text-danger">
                First name is required.
              </div>
            </div>
            <div class="mb-3">
              <label for="lastName" class="form-label">Last Name</label>
              <input type="text" class="form-control" id="lastName" formControlName="lastName" placeholder="Enter your last name">
              <div *ngIf="userForm.controls['lastName'].invalid && userForm.controls['lastName'].touched" class="text-danger">
                Last name is required.
              </div>
            </div>
            <div class="mb-3">
              <label for="email" class="form-label">Email Address</label>
              <input #emailInput type="email" class="form-control" id="email" formControlName="email" placeholder="Enter your email address">
              <div *ngIf="userForm.controls['email'].invalid && userForm.controls['email'].touched" class="text-danger">
                Valid email is required.
              </div>
            </div>
            <div class="form-check mb-3">
              <input class="form-check-input" type="checkbox" id="agree" formControlName="agree">
              <label class="form-check-label" for="agree">
                I agree to receive email updates from us only about Expedisplore and your data will not be used for any other purpose by us.
              </label>
              <div *ngIf="userForm.controls['agree'].invalid && userForm.controls['agree'].touched" class="text-danger">
                You must agree before submitting.
              </div>
            </div>
            <div class="modal-footer d-flex justify-content-between">
              <button type="button" class="btn btn-secondary" (click)="handleCancel()" data-bs-dismiss="modal">Cancel</button>
              <button type="submit" class="btn btn-primary" [disabled]="userForm.invalid || isSubmitting" style="background-color: #FF9800; color: black; border: none;">
                <span *ngIf="isSubmitting" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                <span *ngIf="!isSubmitting">Submit</span>
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
