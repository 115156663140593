import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class GoogleSigninService {
  private googleApiLoaded = false;
  private callbacks: Array<() => void> = [];

  constructor() {
    this.loadGoogleApi();
  }

  private loadGoogleApi(): void {
    const script = document.createElement('script');
    script.src = 'https://accounts.google.com/gsi/client';
    script.async = true;
    script.defer = true;
    script.onload = () => {
      this.googleApiLoaded = true;
      this.callbacks.forEach(callback => callback());
    };
    document.head.appendChild(script);
  }

  public onGoogleApiLoad(callback: () => void): void {
    if (this.googleApiLoaded) {
      callback();
    } else {
      this.callbacks.push(callback);
    }
  }
}
